import React, { useState } from 'react';
import httpClient from '../../httpClient';
import './ServerSetup.css';

const ServerSetup = () => {
    const [monServer, setMonServer] = useState('');
    const [sudoUser, setSudoUser] = useState('');
    const [sudoPassword, setSudoPassword] = useState('');
    const [isPrometheusAuthNeeded, setIsPrometheusAuthNeeded] = useState(false);
    const [prometheusUser, setPrometheusUser] = useState('');
    const [prometheusPassword, setPrometheusPassword] = useState('');

    const handlePrometheusAuthToggle = () => {
        setIsPrometheusAuthNeeded(!isPrometheusAuthNeeded);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        const serverSetupData = {
            monServer,
            sudoUser,
            sudoPassword,
            prometheusUser: isPrometheusAuthNeeded ? prometheusUser : '',
            prometheusPassword: isPrometheusAuthNeeded ? prometheusPassword : '',
        };

        try {
            // Send the data as JSON to the '/installserver' API
            const response = await httpClient.post('/installserver', serverSetupData);
            console.log(response.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="row">
            <div className="col-md-6 offset-md-3">
                <form onSubmit={handleSubmit} className="server-setup-form">
                    <div className="form-group">
                        <label htmlFor="mon-server">Monitoring Server:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="mon-server"
                            value={monServer}
                            onChange={(e) => setMonServer(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="sudo-user">Sudo User:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="sudo-user"
                            value={sudoUser}
                            onChange={(e) => setSudoUser(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="sudo-password">Sudo Password:</label>
                        <input
                            type="password"
                            className="form-control"
                            id="sudo-password"
                            value={sudoPassword}
                            onChange={(e) => setSudoPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <div className="form-check form-switch">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="prometheus-auth-toggle"
                                checked={isPrometheusAuthNeeded}
                                onChange={handlePrometheusAuthToggle}
                            />
                            <label className="form-check-label" htmlFor="prometheus-auth-toggle">
                                Is authentication on Prometheus needed?
                            </label>
                        </div>
                    </div>
                    {isPrometheusAuthNeeded && (
                        <div className='form-group'>
                            <div>
                                <label htmlFor="prometheus-user">Prometheus User:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="prometheus-user"
                                    value={prometheusUser}
                                    onChange={(e) => setPrometheusUser(e.target.value)}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="prometheus-password">Prometheus Password:</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="prometheus-password"
                                    value={prometheusPassword}
                                    onChange={(e) => setPrometheusPassword(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                    )}
                    <div>
                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ServerSetup;
